import {
  currentUserUnpaidOrdersQuery_currentUser_unpaidOrders,
  currentUserUnpaidOrdersQuery
} from '../../core-types';
import { useCurrentUser } from '../../lib/core/useCurrentUser';
import { useQuery } from '@apollo/client';
import { CURRENT_USER_UNPAID_ORDERS_QUERY } from './graphql';

export function useUnpaidOrders(): {
  loading: boolean;
  hasUnpaidOrders: boolean;
  unpaidOrders: currentUserUnpaidOrdersQuery_currentUser_unpaidOrders[];
} {
  const { currentUser } = useCurrentUser();
  const skip = !currentUser || !currentUser.hasUnpaidOrders;
  const { data, loading } = useQuery<currentUserUnpaidOrdersQuery>(
    CURRENT_USER_UNPAID_ORDERS_QUERY,
    {
      skip,
      ssr: false
    }
  );
  let unpaidOrders: currentUserUnpaidOrdersQuery_currentUser_unpaidOrders[] = [];
  if (data && data.currentUser && data.currentUser.unpaidOrders) {
    unpaidOrders = data.currentUser.unpaidOrders;
  }
  if (skip || loading || !unpaidOrders || unpaidOrders.length === 0) {
    return { loading, unpaidOrders: [], hasUnpaidOrders: false };
  }
  return { loading, unpaidOrders, hasUnpaidOrders: unpaidOrders.length > 0 };
}
