import { gql } from '@apollo/client';

export const CURRENT_USER_UNPAID_ORDERS_QUERY = gql`
  query currentUserUnpaidOrdersQuery {
    currentUser {
      id
      unpaidOrders {
        id
        amount
        type
        createdAt
      }
    }
  }
`;

export const PAY_OFF_UNPAID_ORDERS_MUTATION = gql`
  mutation payoffUnpaidOrdersMutation {
    payoffUnpaidOrders {
      id
    }
  }
`;
