import styled from 'styled-components';
import { BoxProps } from '@urbaninfrastructure/react-ui-kit';
import { Box } from '../../components';

const Container = styled(Box)<BoxProps>``;

Container.defaultProps = {
  mx: 'auto',
  px: 4,
  maxWidth: 'lg'
};

export default Container;
